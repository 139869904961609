import { Preferences } from "@capacitor/preferences";

export async function request(url, options, callback) {
  const id = await getItem("id");

  return fetch(url, {
    ...options,
    headers: {
      ...((options || {}).headers || {}),
      "x-id": id || "",
    },
  }).then(async (res) => {
    if (res.ok) {
      if (callback) {
        try {
          callback(null, await res.text());
        } catch (err) {
          console.error(new Error(err));
          callback("Something went wrong");
        }
      } else {
        return res.text();
      }
      return;
    }
    if (callback) {
      try {
        callback(res.status, await res.text());
      } catch (err) {
        console.error(new Error(err));
        callback("Something went wrong");
      }
    } else {
      throw new Error((await res.text()) || res.status);
    }
  });
}

export async function get(url, options, callback) {
  return await request(
    url,
    {
      ...(options || {}),
      method: "GET",
    },
    callback
  );
}

export async function post(url, options, callback) {
  return await request(
    url,
    {
      ...(options || {}),
      method: "POST",
      headers: {
        ...((options || {}).headers || {}),
        "Content-Type": "application/json",
      },
    },
    callback
  );
}

async function getItem(key) {
  return (await Preferences.get({ key })).value;
}
