import Map from "./map.js";
import Fly from "./fly.js";
import Menu from "./menu.js";
import Plan from "./plan.js";
import Info from "./info.js";
import MenuButton from "./menubutton.js";
import Bottombar from "./bottombar.js";
import Notifications from "./notifications.js";
import Online from "./online.js";
import Search from "./search.js";
import Emergency from "./emergency.js";
import DrawControl from "./drawcontrol.js";
import Browse from "./browse.js";
import Banner from "./banner.js";
import { Help, HelpButton } from "./help.js";
import NotificationButton from "./notification-button.js";
import Terms from "./terms.js";
import SwitchMap from "./switch-map.js";
import Time from "./time.js";
import geolocation from "./geolocation.js";

const { redom } = window;
const { el, place } = redom;

geolocation(); // no-op, just to make linter happy

export default class App {
  constructor({ api, i18n }) {
    const app = this;
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      ".app",
      (this.map = new Map({ app, api, i18n })),
      (this.online = new Online({ app, api, i18n })),
      (this.helpButton = new HelpButton({ api, app, i18n })),
      (this.switchMap = new SwitchMap({ app, api, i18n })),
      (this.time = place(Time, { api, app, i18n })),
      (this.search = new Search({ app, api, i18n })),
      (this.bottombar = place(Bottombar, { app, api, i18n })),
      (this.browse = place(Browse, { app, api, i18n })),
      (this.notificationsButton = place(NotificationButton, {
        app,
        api,
        i18n,
      })),
      (this.menubutton = new MenuButton({ app, api, i18n })),
      (this.fly = place(Fly, { app, api, i18n })),
      (this.emergency = place(Emergency, { app, api, i18n })),
      (this.draw = place(DrawControl, { api, app, i18n })),
      (this.plan = place(Plan, { api, app, i18n })),
      (this.info = place(Info, { api, app, i18n })),
      (this.menu = place(Menu, { app, api, i18n })),
      (this.help = place(Help, { app, api, i18n })),
      (this.banner = place(Banner, { app, api, i18n })),
      (this.notifications = place(Notifications, { app, api, i18n })),
      (this.terms = place(Terms, { app, api, i18n }))
    );
  }

  update(api) {
    const { i18n } = this;

    const { HIDE_CLOCK } = window.ENV;

    document.title = i18n("title", "Loading...");

    const showInfo =
      api.features && api.features.length && !(api.drawPlan || api.createPlan);

    this.map.update(api);
    this.switchMap.update(api);
    this.menubutton.update(api);
    this.menu.update(api.menuOpened || api.menuClosing, api);
    this.fly.update(!(api.drawPlan || api.createPlan), api);
    this.notificationsButton.update(!(api.drawPlan || api.createPlan), api);
    this.draw.update(api.drawPlan || api.drawPlanClosing, api);
    this.plan.update(api.createPlan || api.drawPlan, api);
    this.info.update(showInfo, api);
    this.bottombar.update(true, api);
    this.notifications.update(true, api);
    this.online.update(api);
    this.search.update(api);
    this.time.update(!HIDE_CLOCK || api.browseTimeLocked, api);
    this.emergency.update(false && api.activePlans.length);

    this.help.update(api.help, api);
    this.helpButton.update(api);

    this.banner.update(!api.bannerAcknowledged && i18n("BANNER", ""), api);
    this.terms.update(!api.termsRead && i18n("TERMS", ""), api);

    if (api.query.operationplanbrowser != null) {
      this.browse.update(true, api);
    }
  }
}
