const { redom } = window;
const { el, list } = redom;

const hours = [];
const minutes = [];

for (let i = 0; i < 24; i++) {
  hours.push(i);
}

for (let i = 0; i < 60; i++) {
  minutes.push(i);
}

let id = 0;

export default class DatetimeInput {
  constructor({ i18n, value }) {
    this.id = id++;
    this.i18n = i18n;
    this.el = el(
      ".datetime-input",
      (this.input = el("input.input", {
        autocomplete: "off",
      })),
      el("i.ti.ti-calendar-time"),
      (this.selector = el(
        ".selector",
        { style: { display: "none" } },
        (this.dateSelector = el(
          ".date-selector",
          (this.monthName = el(".month")),
          el(
            "table",
            el("thead", (this.weekdayNames = list("tr", Td))),
            (this.cal = list("tbody", Week))
          )
        )),
        (this.timeSelector = el(
          ".time-selector",
          (this.hours = list(".hours", Hour, null, {
            onclick: (hour) => {
              this.value || (this.value = new Date());
              this.value.setHours(hour);
              this.update();
              this.el
                .querySelector(".hour.current")
                .scrollIntoView({ block: "center" });
              this.input.oninput();
            },
          })),
          (this.minutes = list(".minutes", Minute, null, {
            onclick: (minute) => {
              this.value || (this.value = new Date());
              this.value.setMinutes(minute);
              this.update();
              this.el
                .querySelector(".minute.current")
                .scrollIntoView({ block: "center" });
              this.input.oninput();
            },
          }))
        ))
      ))
    );
    this.input.onfocus = () => {
      this.onfocus && this.onfocus();
      this.showSelector();
      this.el
        .querySelector(".hour.current")
        .scrollIntoView({ block: "center" });
      this.el
        .querySelector(".minute.current")
        .scrollIntoView({ block: "center" });
      window.addEventListener("mousedown", this);
      window.addEventListener("touchstart", this);
    };
    this.input.oninput = () => {
      this.oninput && this.oninput();
      const [dateString, timeString] = this.input.value.split(" ");
      const [d, m, y] = (dateString || "").split(".");
      const [h, min] = (timeString || "").split(":");

      const str = `${y || new Date().getFullYear()}-${pad(
        m != null ? m : new Date().getMonth() + 1
      )}-${pad(d || new Date().getDate())}T${pad(
        h !== "" ? h : new Date().getHours()
      )}:${pad(min != null ? min : new Date().getMinutes())}`;

      const date = new Date(str);

      if (date.toString() !== "Invalid Date") {
        this.value = date;
        this.update(true);
        this.el
          .querySelector(".hour.current")
          .scrollIntoView({ block: "center" });
        this.el
          .querySelector(".minute.current")
          .scrollIntoView({ block: "center" });
      }
    };
    this.input.onblur = () => {
      this.onblur && this.onblur();
      this.update();
    };
    this.el.onmousedown = this.el.ontouchstart = (e) => {
      e[`datetimeInputClick${this.id}`] = true;
    };
    if (value) {
      this.value = value;
      this.update();
    }
  }

  handleEvent(e) {
    if (e.type === "mousedown" || e.type === "touchstart") {
      if (e[`datetimeInputClick${this.id}`]) {
        return;
      }
      this.hideSelector();
      window.removeEventListener("mousedown", this);
      window.removeEventListener("touchstart", this);
      this.onblur && this.onblur();
    }
  }

  focus() {
    this.input.focus();
    this.input.onfocus && this.input.onfocus();
  }

  showSelector() {
    this.selector.style.display = "";
    this.update();
  }

  hideSelector() {
    this.selector.style.display = "none";
  }

  update(noValueUpdate) {
    const { i18n } = this;
    this.weekdayNames.update(
      "monday tuesday wednesday thursday friday saturday sunday"
        .split(" ")
        .map((name) => {
          return i18n(`cal.day.${name}`).slice(0, 2);
        })
    );

    const date = this.value || new Date();

    date.setSeconds(0, 0);

    if (document.activeElement !== this.input) {
      this.input.value = humanDateTime(date);
    }

    const _hour = date.getHours();
    const _minute = date.getMinutes();

    this.cal.update(this.getCalData(date));
    this.hours.update(
      hours.map((hour) => {
        return {
          hour,
          current: _hour === hour,
        };
      })
    );
    this.minutes.update(
      minutes.map((minute) => {
        return {
          minute,
          current: _minute === minute,
        };
      })
    );

    this.timeSelector.style.height = this.dateSelector.clientHeight + "px";
  }

  getCalData(date) {
    const { i18n } = this;
    const monthNames =
      "january february march april may june july august september october november december".split(
        " "
      );
    const monthName = i18n(`cal.month.${monthNames[date.getMonth()]}`);

    this.monthName.textContent = `${monthName} ${date.getFullYear()}`;

    const startOfMonth = new Date(
      `${date.getFullYear()}-${pad(date.getMonth() + 1)}-01`
    );
    const endOfMonth = new Date(startOfMonth);

    endOfMonth.setMonth(startOfMonth.getMonth() + 1);
    endOfMonth.setDate(endOfMonth.getDate() - 1);

    const start = new Date(startOfMonth);
    const end = new Date(endOfMonth);

    const startDay = (start.getDay() + 6) % 7;
    const endDay = (end.getDay() + 6) % 7;

    if (startDay === 0) {
      start.setDate(start.getDate() - 7);
    } else {
      start.setDate(start.getDate() - startDay);
    }

    if (endDay === 6) {
      end.setDate(end.getDate() + 7);
    } else {
      end.setDate(end.getDate() + (6 - endDay));
    }

    let week = { days: [] };
    const results = [week];

    const iterate = new Date(start);

    while (true) {
      const currentIterate = new Date(iterate);
      if (week.days.length === 7) {
        week = { days: [] };
        results.push(week);
      }

      const day = {
        number: currentIterate.getDate(),
        onclick: () => {
          this.value || (this.value = new Date());
          this.value.setDate(currentIterate.getDate());
          this.value.setMonth(currentIterate.getMonth());
          this.value.setFullYear(currentIterate.getFullYear());
          this.update();
          this.el
            .querySelector(".hour.current")
            .scrollIntoView({ block: "center" });
          this.el
            .querySelector(".minute.current")
            .scrollIntoView({ block: "center" });
          this.input.oninput();
        },
      };

      if (equalDate(currentIterate, date)) {
        day.currentDay = true;
      }

      if (currentIterate >= startOfMonth && currentIterate <= endOfMonth) {
        day.currentMonth = true;
      }

      week.days.push(day);

      if (currentIterate < end) {
        iterate.setDate(currentIterate.getDate() + 1);
      } else {
        break;
      }
    }
    return results;
  }
}

class Td {
  constructor() {
    this.el = el("td");
  }

  update(str) {
    this.el.textContent = str;
  }
}

class Week {
  constructor() {
    this.el = list("tr", Day);
  }

  update(data) {
    this.el.update(data.days);
  }
}

class Day {
  constructor() {
    this.el = el("td.day");
  }

  update(data) {
    const { number, currentMonth, currentDay, onclick } = data;
    this.el.textContent = number;

    this.el.onclick = onclick;

    this.el.classList[currentMonth ? "add" : "remove"]("current-month");
    this.el.classList[currentDay ? "add" : "remove"]("current-day");
  }
}

function equalDate(date, date2) {
  if (date.getDate() !== date2.getDate()) {
    return false;
  } else if (date.getMonth() !== date2.getMonth()) {
    return false;
  } else if (date.getFullYear() !== date2.getFullYear()) {
    return false;
  }
  return true;
}

function humanDateTime(date) {
  return `${pad(date.getDate())}.${pad(
    date.getMonth() + 1
  )}.${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

function pad(num) {
  return `0${num}`.slice(-2);
}

class Hour {
  constructor({ onclick }) {
    this.onclick = onclick;
    this.el = el(".hour");
  }

  update(data) {
    const { hour, current } = data;
    this.el.textContent = pad(hour);
    this.el.classList[current ? "add" : "remove"]("current");
    this.el.onclick = () => {
      this.onclick(hour);
    };
  }
}

class Minute {
  constructor({ onclick }) {
    this.onclick = onclick;
    this.el = el(".minute");
  }

  update(data) {
    const { minute, current } = data;
    this.el.textContent = pad(minute);
    this.el.classList[current ? "add" : "remove"]("current");
    this.el.onclick = () => {
      this.onclick(minute);
    };
  }
}
