import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Device } from "@capacitor/device";
import getPlanArea from "../get-plan-area.js";
import { getItem, setItem, removeItem } from "../storage.js";
import { request, get, post } from "../request.js";
import { PushNotifications } from "@capacitor/push-notifications";
import { v1 as uuidv1 } from "uuid";

const { fetch, turf, requestAnimationFrame, location, Keycloak } = window;

const platform = Capacitor.getPlatform();

export default class Api {
  constructor({ i18n }) {
    this.i18n = i18n;
    this.query = getQuery();
    this.drawingMode = "circle";
    this.currentDrawing = [];
    this.buffer = 50;
    // this.segment = 10;
    this.basemap = "";
    this.createPlan = false;
    this.menuSection = "";
    this.DATA = {
      alerts: [],
      telemetry: [],
      telemetryLookup: {},
    };
    this.drones = [];
    this.dronesById = {};
    this.operationplans = [];
    this.operationplansLookup = {};
    this.authorizations = {};
    this.activations = {};
    this.activePlans = [];
    this.waitingPlans = [];
    this.closedPlans = [];
    this.rejectedPlans = [];
    this.cancelledPlans = [];
    this.loggedPlans = [];
    this.features = [];

    const hash = (this.hash = getHash());

    if (hash.menu) {
      this.menuSection = hash.menu;
      this.menuOpened = true;
    }

    if (hash.menuid) {
      this.menuId = hash.menuid;
    }

    this.sortFeatures = (features = this.features) => {
      features.sort((a, b) => {
        const { lowerMeters: lowerMetersA, upperMeters: upperMetersA } =
          a.properties;
        const { lowerMeters: lowerMetersB, upperMeters: upperMetersB } =
          b.properties;

        if (lowerMetersA === lowerMetersB) {
          return upperMetersA - upperMetersB;
        } else {
          return lowerMetersA - lowerMetersB;
        }
      });

      const restrictionIndex = {
        NO_RESTRICTION: 0,
        CONDITIONAL: 1,
        REQ_AUTHORISATION: 2,
        PROHIBITED: 3,
      };

      features.sort((a, b) => {
        const { restriction: restrictionA, _rejecting: _rejectingA } =
          a.properties;
        const { restriction: restrictionB, _rejecting: _rejectingB } =
          b.properties;
        const indexA = _rejectingA ? 3 : restrictionIndex[restrictionA];
        const indexB = _rejectingB ? 3 : restrictionIndex[restrictionB];

        return indexB - indexA;
      });

      const sourceIndex = {
        coordinate: 2,
        "weather-observations": 1,
      };

      features.sort((a, b) => {
        const aIndex = sourceIndex[a.source] || -1;
        const bIndex = sourceIndex[b.source] || -1;

        return bIndex - aIndex;
      });
    };

    this.hello(this.query);

    CapacitorApp.addListener("appUrlOpen", async (event) => {
      const split = event.url.split("/");

      const action = split[2];
      const code = decodeURIComponent(split[3]);

      if (action === "code") {
        this.hello({ code });
      } else if (action === "logout") {
        this.hello({ logout: true });
      }

      try {
        Browser.close();
      } catch (err) {
        console.error(err);
      }
    });

    setTimeout(() => this.fetchPlans(), 60 * 1000);

    this.updateEnv();
  }

  updateEnv() {
    const { HOST } = window.ENV;

    for (const key in window.ENV) {
      const value = window.ENV[key];

      if (value === "false" || value === "0") {
        window.ENV[key] = false;
      }
    }

    fetch(HOST + "env")
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          setTimeout(() => {
            this.updateEnv();
          }, 5000);
        }
      })
      .then((res) => res.json())
      .then(async (results) => {
        for (const key in results) {
          if (key === "APP_ID" || key === "MOBILE") {
            continue;
          }
          const value = results[key];
          if (key === "DEFAULT_LANG") {
            const lang = await getItem("lang");
            if (lang == null) {
              await setItem("lang", value || "");
              location.reload();
            }
          }
          if (value === "false" || value === "0") {
            window.ENV[key] = false;
          } else {
            window.ENV[key] = results[key];
          }
        }
        this.envUpdated = true;
        this.update();
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => {
          this.updateEnv();
        }, 5000);
      });
  }

  getPlanFeatures() {
    const json = this.uasData;
    const { routeArea } = getPlanArea(this);

    let union = routeArea.features[0];

    for (let i = 1; i < routeArea.features.length; i++) {
      const feature = routeArea.features[i];
      union = turf.union(turf.featureCollection([union, feature]));
    }

    const results = json.features.filter((feature) => {
      try {
        return turf.booleanIntersects(turf.feature(feature.geometry), union);
      } catch (err) {
        console.error(err);
      }
      return false;
    });

    return results;
  }

  async fetchUAS(force, updates) {
    const { HOST } = window.ENV;

    if (!force && this.lastUASFetchRequestAt > Date.now() - 5000) {
      clearTimeout(this.fetching);
      this.fetchingUAS = setTimeout(() => this.fetchUAS(), 5000);
      return;
    }
    this.lastUASFetchRequestAt = Date.now();

    clearTimeout(this.fetching);
    this.fetchingUAS = setTimeout(() => this.fetchUAS(), 60 * 1000);

    let url = HOST + "utm/uas.geojson";

    if (this.browseTime) {
      const start = this.browseTime;
      const end = this.browseTime;

      url += `?start=${start.toISOString()}&end=${end.toISOString()}&buffer=${
        this.browseBuffer
      }`;
    }

    if (updates) {
      this.isFetchingUAS = true;
    }

    fetch(url)
      .then((res) => {
        if (updates) {
          this.isFetchingUAS = false;
        }
        if (!res.ok) throw new Error(res.status);
        return res;
      })
      .then((res) => res.json())
      .then((geojson) => {
        this.uasData = geojson;
        const uas = this.app.map.map.getSource("uas");
        uas && uas.setData(geojson);
      });
  }

  async fetchPlans(force) {
    const { HOST, FLIGHT_PAUSE } = window.ENV;

    if (!force && this.lastPlansFetchRequestAt > Date.now() - 5 * 1000) {
      clearTimeout(this.fetching);
      this.fetchingPlans = setTimeout(() => this.fetchPlans(), 5 * 1000);
      return;
    }

    this.app.map.reloadJSON("operationplans");

    this.lastPlansFetchRequestAt = Date.now();

    clearTimeout(this.fetchingPlans);
    this.fetchingPlans = setTimeout(() => this.fetchPlans(), 60 * 1000);

    const { user } = this;

    try {
      if (!this.helloInit) {
        setTimeout(() => {
          this.fetchPlans(true, true);
        }, 100);
        return;
      }
      if (user) {
        let url = `${HOST}utm/operationplans.json`;
        const id = await getItem("id");

        if (this.browseTime) {
          const start = this.browseTime;
          const end = this.browseTime;

          url += `?start=${start.toISOString()}&end=${end.toISOString()}`;
          if (id) {
            url += `&id=${id}`;
          }
        } else {
          if (id) {
            url += `?id=${id}`;
          }
        }

        const json = await get(url);
        const plans = JSON.parse(json);
        const activePlans = (plans || []).filter(
          (plan) => plan.state === "ACTIVATED"
        );
        const waitingPlans = (plans || []).filter(
          (plan) => plan.state !== "CLOSED" && plan.state !== "ACTIVATED"
        );
        const closedPlans = (plans || []).filter(
          (plan) => plan.state === "CLOSED"
        );

        const { authorizations, activations } = this;

        this.operationplans = plans;
        this.operationplansLookup = plans.reduce((lookup, plan) => {
          Object.defineProperties(plan, {
            authorization: {
              get: () => {
                return authorizations[plan.operationPlanId];
              },
            },
            activation: {
              get: () => {
                return activations[plan.operationPlanId];
              },
            },
            completed: {
              get: () => {
                return (
                  plan.state === "CLOSED" &&
                  plan.authorization &&
                  plan.authorization.state === "GRANTED" &&
                  plan.activation &&
                  plan.activation.state === "GRANTED"
                );
              },
            },
            cancelled: {
              get: () => {
                return (
                  !plan.completed && plan.state === "CLOSED" && !plan.rejected
                );
              },
            },
            rejected: {
              get: () => {
                const rejectionStates = ["DENIED", "TIMEOUT", "ERROR"];

                try {
                  if (plan.authorization) {
                    const startTime = plan.operationVolumes[0].timeBegin;

                    for (const op of plan.authorization.alternativeOPs || []) {
                      const _newStartTime = op.operationVolumes[0].timeBegin;

                      if (_newStartTime !== startTime) {
                        return true;
                      }
                    }
                  }
                } catch (err) {
                  console.error(new Error(err));
                }

                if (plan.completed) {
                  return false;
                }

                if (plan.state === "CLOSED") {
                  if (
                    plan.authorization &&
                    plan.authorization.state === "DENIED"
                  ) {
                    if (
                      plan.authorization &&
                      plan.authorization.conflicts &&
                      plan.authorization.conflicts.length
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  } else if (
                    plan.activation &&
                    plan.activation.state === "DENIED"
                  ) {
                    if (
                      plan.activation &&
                      plan.activation.conflicts &&
                      plan.activation.conflicts.length
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }

                return (
                  plan.state === "CLOSED" &&
                  (() => {
                    const authorizationState =
                      plan.authorization &&
                      rejectionStates.includes(plan.authorization.state);

                    const activationState =
                      plan.activation &&
                      rejectionStates.includes(plan.activation.state);

                    return authorizationState || activationState;
                  })()
                );
              },
            },
          });

          lookup[plan.operationPlanId] = plan;
          return lookup;
        }, {});
        this.activePlans = activePlans;
        this.waitingPlans = waitingPlans;
        this.closedPlans = closedPlans;
        this.update();
        this.fetchDrones();

        if ((this.menuSection || "").includes("operationplan")) {
          await Promise.all(
            closedPlans.map(async (plan) => {
              for (const phase of ["authorization", "activation"]) {
                try {
                  const result = await fetch(
                    `${HOST}utm/${phase}/${plan.operationPlanId}`,
                    {
                      priority: "low",
                    }
                  ).then((res) => res.ok && res.json());
                  this[phase + "s"][plan.operationPlanId] = result;

                  if (
                    result.state !== "GRANTED" &&
                    result.state !== "GRANTED_WITH_RESTRICTIONS"
                  ) {
                    break;
                  }
                } catch (err) {
                  // no-op
                }
              }
            })
          );
          const loggedPlans = closedPlans.filter((plan) => plan.completed);
          const cancelledPlans = closedPlans.filter((plan) => plan.cancelled);
          const rejectedPlans = closedPlans.filter((plan) => plan.rejected);

          this.loggedPlans = loggedPlans;
          this.cancelledPlans = cancelledPlans;
          this.rejectedPlans = rejectedPlans;
          this.update();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  get allNotifications() {
    const lookup = {};
    return (
      this.DATA.alerts
        .filter((alert) => {
          const { _id } = alert;

          if (lookup[_id]) {
            return false;
          }
          lookup[_id] = true;
          return true;
        })
        .filter((alert) => {
          const { operationPlanIds = [], _own } = alert;

          if (_own) {
            return true;
          }

          for (let i = 0; i < operationPlanIds.length; i++) {
            const id = operationPlanIds[i];

            if (this.operationplansLookup[id]) {
              return true;
            }
          }
          return false;
        }) || []
    );
  }

  get notifications() {
    return this.allNotifications.filter(
      (notification) => !notification.acknowledged
    );
  }

  async hello(args) {
    const {
      HOST,
      APP_ID,
      SKYZR_KEYCLOAK,
      SKYZR_CLIENT_ID,
      SKYZR_REALM,
      PUSH_NOTIFICATIONS,
    } = window.ENV;

    try {
      clearTimeout(this.scheduleHello);
      this.scheduleHello = setTimeout(() => {
        this.hello();
      }, 60 * 1000);

      const { code: authorizationCode, logout } = args || {};

      if (authorizationCode) {
        const id = Date.now() + "." + uuidv1();
        await setItem("id", id);
      }

      let url = `${HOST}auth/skyzr/hello`;

      if (platform !== "web") {
        url += "?app";

        if (APP_ID) {
          url += "=" + APP_ID;
        }
      }

      if (logout != null) {
        console.log("logout");
        await request(url, { method: "DELETE" });
        await removeItem("id");
        if (location.search) {
          location.search = "?";
        } else {
          location.reload();
        }
        return;
      }

      const body = JSON.stringify({
        authorizationCode,
      });

      const hello = await post(url, { body });

      this.helloInit = true;

      const parsed = JSON.parse(hello || "{}");

      if (parsed.redirect) {
        if (platform !== "web") {
          Browser.open({ url: parsed.redirect });
        } else {
          location.href = parsed.redirect;
        }
        return;
      }

      if (authorizationCode) {
        if (location.search) {
          location.search = "";
        } else {
          location.reload();
        }
        return;
      }

      const { user, environments } = parsed;

      if (!user || user.error) {
        this.user = null;
        this.environments = null;
        this.update();

        if (platform === "web" && !location.href.includes("?")) {
          const keycloak = new Keycloak({
            url: SKYZR_KEYCLOAK + "/auth",
            realm: SKYZR_REALM,
            clientId: SKYZR_CLIENT_ID,
          });

          keycloak
            .init({
              onLoad: "check-sso",
              silentCheckSsoRedirectUri: HOST + "silent-check-sso.html",
            })
            .then((authenticated) => {
              if (authenticated) {
                location.href = HOST + "auth/skyzr/check-sso";
              }
            })
            .catch((err) => {
              err && console.error(err);
            });
        }
        return;
      }

      this.user = user;
      this.environments = environments;
      this.operator =
        (user && user.company && user.company.registrationNumber) || user.email;
      this.update();

      if (PUSH_NOTIFICATIONS) {
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === "granted") {
            PushNotifications.register();
          }
        });

        PushNotifications.addListener("registration", async (token) => {
          const url = `${HOST}auth/skyzr/pushnotifications`;

          post(url, {
            body: JSON.stringify({
              token: token.value,
            }),
          }).then(noOp);
        });

        PushNotifications.addListener("registrationError", (error) => {
          console.log("Error on registration: " + JSON.stringify(error));
        });

        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log("Push received: " + JSON.stringify(notification));
          }
        );

        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            console.log(
              "Push action performed: " + JSON.stringify(notification)
            );
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchDrones() {
    const { HOST } = window.ENV;

    try {
      if (!this.user) {
        this.drones = [];
        return;
      }
      if (!this.user.active) {
        this.drones = [];
        return;
      }
      this.drones = JSON.parse(
        await get(`${HOST}auth/skyzr/drones`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            orgId: this.user.orgId,
          },
        })
      );

      this.dronesById = this.drones.reduce((lookup, drone) => {
        lookup[drone.serial] = drone;
        lookup[drone.networkId] = drone;
        return lookup;
      }, {});

      this.update();
    } catch (err) {
      console.error(err);
      this.drones = [];
      this.update();
    }
  }

  i18n() {
    return "";
  }

  syncHash() {
    const hashChanges = [];
    if (this.menuSection !== this._menuSection) {
      this._menuSection = this.menuSection;
      hashChanges.push(["menu", this.menuSection || undefined]);
      if (this.menuSection !== "operationplan") {
        this.menuId = undefined;
      }
    }
    if (this.menuId !== this._menuId) {
      this._menuId = this.menuId;
      hashChanges.push(["menuid", this.menuId || undefined]);
    }
    if (hashChanges.length) {
      const hash = getHash();
      for (const [key, value] of hashChanges) {
        hash[key] = value;
      }
      setHash(hash);
    }
  }

  update() {
    const { STATS_ENABLED } = window.ENV;
    if (STATS_ENABLED && !this.statsSent) {
      if (this.helloInit && this.envUpdated) {
        this.statsSent = true;
        this.sendStats("pageload");
        document.addEventListener("visibilitychange", () => {
          if (!document.hidden) {
            this.sendStats("visibilitychange");
          }
        });
      }
    }
    if (this.updating) {
      return;
    }
    this.updating = requestAnimationFrame(() => {
      this.updating = null;

      this.syncHash();

      this.app.update(this);
    });
  }

  async sendStats(event) {
    const { HOST } = window.ENV;
    fetch(HOST + "stat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: uuidv1(),
        timestamp: new Date(),
        application: `flyk-${platform}`,
        event,
        userIdAnonymized:
          this.user?.sub && (await digestMessage(this.user.sub)),
        payload: {
          uuid: await digestMessage(await Device.getId()),
          userAgent: navigator.userAgent,
        },
      }),
    });
  }
}

function getQuery() {
  const results = {};

  location.search
    .slice(1)
    .split("&")
    .forEach((part) => {
      const [key, value] = part.split("=");

      if (value == null) {
        results[key] = true;
      } else {
        results[key] = decodeURIComponent(value);
      }
    });

  return results;
}

function noOp() {}

function getHash() {
  const results = {};
  const split = location.hash.slice(1).split("&");

  for (const part of split) {
    const [key, value] = part.split("=");

    results[key] = value;
  }

  return results;
}

function setHash(obj) {
  const results = [];
  for (const key in obj) {
    const value = obj[key];

    if (value !== undefined) {
      if (value == null) {
        results.push(key);
      } else {
        results.push(`${key}=${value}`);
      }
    }
  }
  location.hash = `#${results.join("&")}`;
}

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}
