export default async function serverTime() {
  try {
    const { BASE_URL, RELATIVE_PATH } = window.ENV;
    return new Date(
      (await fetch(`${BASE_URL}${RELATIVE_PATH}time`).then((res) => res.json()))
        .now || new Date()
    );
  } catch (err) {
    console.error(err);
    return new Date();
  }
}
