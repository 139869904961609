import getPlanArea from "./get-plan-area.js";
import { metersToFeet } from "./units.js";
import { post } from "./request.js";
import { v1 as uuidv1 } from "uuid";
import Feature from "./info-feature.js";
import DatetimeInput from "./datetime-input.js";
import Droplist from "./droplist.js";
import serverTime from "./server-time.js";

const { alert, redom } = window;
const { el, list, place } = redom;

const operationTypes = {
  REMOTELY_PILOTED_VLOS: "vlos",
  REMOTELY_PILOTED_BVLOS: "bvlos",
  AUTOMATED_FLIGHT: "automated",
  ICAO_FLIGHT: "icao",
};

export default class Plan {
  constructor({ app, api, i18n }) {
    const {
      SKYZR_FRONTEND,
      VLOS_MAX_FLIGHT_DURATION,
      VLOS_MAX_LEAD_TIME,
      BVLOS_MAX_FLIGHT_DURATION,
      BVLOS_MAX_LEAD_TIME,
      REQ_AUTHORIZATION_MIN_LEAD_TIME,
      MANDATORY_FIELD_PUBLIC_TITLE,
      MIN_FLIGHT_DURATION = 0,
    } = window.ENV;
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.conflicts = [];

    this.phase = 1;

    this.api.fetchDrones();

    const now = new Date();
    const afterHalfHour = new Date(now);

    afterHalfHour.setMinutes(
      afterHalfHour.getMinutes() + Number(MIN_FLIGHT_DURATION)
    );

    this.el = el(
      ".planwrapper",
      el(
        ".plan",
        (this.content = el(
          ".plan-content",
          (this.form = el(
            "form.form",
            el(
              ".field",
              el("p", (this.contactDetailsText = el("b"))),
              el("br"),
              el(
                ".row",
                el(
                  ".col2",
                  (this.startDatetimeText = el("label")),
                  (this.startDatetime = new DatetimeInput({
                    i18n,
                    value: now,
                  }))
                ),
                el(
                  ".col2",
                  (this.endDatetimeText = el("label")),
                  (this.endDatetime = new DatetimeInput({
                    i18n,
                    value: afterHalfHour,
                  }))
                )
              ),
              (this.timeInfo = el("p", { style: { padding: ".75rem 0" } })),
              el(
                ".row",
                el(
                  ".col2",
                  (this.mtomText = el("label")),
                  (this.mtom = el("input.input", {
                    type: "number",
                    min: 0,
                    autocomplete: "off",
                  }))
                ),
                el(
                  ".col2",
                  (this.maxAltitudeText = el("label")),
                  (this.maxAltitude = el("input.input", {
                    type: "number",
                    min: 0,
                    max: 10000,
                    step: 1,
                    autocomplete: "off",
                  }))
                )
              ),
              el(
                ".row",
                el(
                  ".col2",
                  (this.typeOfOperationText = el("label")),
                  (this.typeOfOperation = new Droplist())
                ),
                el(
                  ".col2",
                  (this.droneText = el("label")),
                  (this.drone = new Droplist()),
                  el("br"),
                  (this.manageDronesText = el("a", {
                    href: `${SKYZR_FRONTEND}/my-organisation/assets/overview`,
                    target: "_blank",
                    style: { fontSize: ".875rem", lineHeight: "1.5rem" },
                  }))
                )
              ),
              el(
                ".row",
                el(
                  ".col2",
                  (this.firstNameText = el("label")),
                  (this.firstName = el("input.input", {
                    autocomplete: "off",
                  }))
                ),
                el(
                  ".col2",
                  (this.lastNameText = el("label")),
                  (this.lastName = el("input.input", {
                    autocomplete: "off",
                  }))
                )
              ),
              el(
                ".row",
                el(
                  ".col2",
                  (this.emailText = el("label")),
                  (this.email = el("input.input", {
                    disabled: true,
                    autocomplete: "off",
                  }))
                ),
                el(
                  ".col2",
                  (this.phoneText = el("label")),
                  (this.phone = el("input.input", {
                    type: "tel",
                    autocomplete: "off",
                  }))
                )
              ),
              el(
                ".row",
                el(
                  ".col",
                  (this.descriptionText = el("label")),
                  (this.description = el("input.input", {
                    autocomplete: "off",
                  }))
                )
              ),
              (this.features = list(".plan-features", Feature, null, this.api)),
              (this.errors = el(".errors", {
                style: { color: "red", padding: "1rem 0" },
              })),
              el(
                "div",
                { style: { textAlign: "right" } },
                (this.cancel = el(
                  "button.button.circle.grey",
                  { type: "reset" },
                  el("i.ti.ti-arrow-left")
                )),
                (this.submitButton = el(
                  "button.button.circle",
                  { type: "submit" },
                  el("i.ti.ti-arrow-right")
                ))
              )
            )
          )),
          (this.briefing = place(Briefing, { api, plan: this, i18n }))
        )),
        (this.close = el(".close", el("i.ti.ti-x")))
      )
    );
    this.planFeatures = this.api.getPlanFeatures();
    this.reqAuthorization = !!this.planFeatures.find((feature) => {
      return feature.properties.restriction === "REQ_AUTHORISATION";
    });
    this.update(this.api);

    this.cancel.onclick = (e) => {
      e.preventDefault();

      if (this.phase === 2) {
        this.phase = 1;
        this.api.update();
        return;
      }

      api.drawPlan = true;
      api.createPlan = null;

      api.update();
    };
    this.close.onclick = (e) => {
      e.preventDefault();

      api.drawPlan = null;
      api.createPlan = null;
      api.update();
    };

    const checkValues = async (field, verify) => {
      try {
        const now = await serverTime();
        const start = new Date(this.startDatetime.value);
        const end = new Date(this.endDatetime.value);

        now.setSeconds(0);
        now.setMilliseconds(0);

        this.api.browseTime = new Date(start.getTime());
        this.api.browseBuffer = Math.ceil(
          (end.getTime() - start.getTime()) / 1000 / 60
        );
        this.api.browseTimeChanged = true;
        this.api.browseTimeLocked = true;

        this.api.fetchUAS(true);
        this.api.update();

        if (field === "start") {
          const maxLeadTime =
            this.typeOfOperation.value === "REMOTELY_PILOTED_VLOS"
              ? VLOS_MAX_LEAD_TIME
              : BVLOS_MAX_LEAD_TIME;

          if (start - now > maxLeadTime * 60 * 1000) {
            !verify && alert(i18n("operationplan.error.flightPlanTooFarAhead"));
            const newStart = new Date(now.getTime() + maxLeadTime * 60 * 1000);
            this.startDatetime.value = newStart;
            this.startDatetime.update();
            return await checkValues("start", true);
          }

          if (this.reqAuthorization) {
            const minLeadTime = REQ_AUTHORIZATION_MIN_LEAD_TIME;
            if (start - now < minLeadTime * 60 * 1000) {
              !verify &&
                alert(
                  i18n(
                    "operationplan.error.authorizationNeededStartTimeTooSoon"
                  )
                );
              const newStart = new Date(
                now.getTime() + minLeadTime * 60 * 1000
              );
              this.startDatetime.value = newStart;
              this.startDatetime.update();
              return await checkValues("start", true);
            }
          }

          if (start < now) {
            !verify && alert(i18n("operationplan.error.startBeforeNow"));
            this.startDatetime.value = now;
            this.startDatetime.update();
            return await checkValues("start", true);
          }
        } else {
          if (end < now) {
            !verify && alert(i18n("operationplan.error.endBeforeNow"));
            this.endDatetime.value = now;
            this.endDatetime.update();
            return await checkValues("end", true);
          }
        }

        const maxDuration =
          this.typeOfOperation.value === "REMOTELY_PILOTED_VLOS"
            ? VLOS_MAX_FLIGHT_DURATION
            : BVLOS_MAX_FLIGHT_DURATION;

        const minDuration = MIN_FLIGHT_DURATION;

        if (start && end) {
          if (end - start > maxDuration * 60 * 1000) {
            !verify && alert(i18n("operationplan.error.durationTooLong"));
            const newEnd = new Date(start.getTime() + maxDuration * 60 * 1000);
            this.endDatetime.value = newEnd;
            this.endDatetime.update();
            return await checkValues("end", true);
          } else if (end - start < minDuration * 60 * 1000) {
            !verify && alert(i18n("operationplan.error.durationTooShort"));
            const newEnd = new Date(start.getTime() + minDuration * 60 * 1000);
            this.endDatetime.value = newEnd;
            this.endDatetime.update();
            return await checkValues("end", true);
          }
        }

        if (start >= end) {
          !verify && alert(i18n("operationplan.error.endBeforeStart"));
          if (field === "start") {
            const newEnd = new Date(start);
            newEnd.setMinutes(newEnd.getMinutes() + MIN_FLIGHT_DURATION);
            this.endDatetime.value = newEnd;
            this.endDatetime.update();
            return await checkValues("end", true);
          } else {
            const newStart = new Date(end);
            newStart.setMinutes(newStart.getMinutes() - MIN_FLIGHT_DURATION);
            this.startDatetime.value = newStart;
            this.startDatetime.update();
            return await checkValues("start", true);
          }
        }

        return verify;
      } catch (err) {
        console.error(new Error(err.stack));
        alert("Something went wrong");
        return;
      }
    };

    this.startDatetime.onblur = async () => await checkValues("start");
    this.endDatetime.onblur = async () => await checkValues("end");
    this.form.onsubmit = async (e) => {
      e.preventDefault();

      const firstName = this.firstName.value;
      const lastName = this.lastName.value;
      const phone = this.phone.value;
      const mtom = this.mtom.value;
      const startDatetime = this.startDatetime.value;
      const endDatetime = this.endDatetime.value;
      const maxAltitude = this.maxAltitude.value;
      const description = this.description.value;

      const errors = [];

      this.firstNameText.style.color = "";
      this.lastNameText.style.color = "";
      this.phoneText.style.color = "";
      this.mtomText.style.color = "";
      this.maxAltitudeText.style.color = "";
      this.startDatetimeText.style.color = "";
      this.endDatetimeText.style.color = "";

      [
        "firstName",
        "lastName",
        "phone",
        "mtom",
        "maxAltitude",
        "description",
      ].forEach((field) => {
        this[field].oninput = () => {
          this[field + "Text"].style.color = "";
        };
      });

      if (api.user) {
        if (!firstName || !lastName) {
          this.firstNameText.style.color = "red";
          this.lastNameText.style.color = "red";
          errors.push(i18n("operationplan.mandatory.name"));
        }

        if (!phone) {
          this.phoneText.style.color = "red";
          errors.push(i18n("operationplan.mandatory.phone"));
        } else if (!validPhone(phone)) {
          this.phoneText.style.color = "red";
          errors.push(i18n("operationplan.invalid.phone"));
        }
      }

      if (!mtom) {
        this.mtomText.style.color = "red";
        errors.push(i18n("operationplan.mandatory.mtom"));
      }

      if (!maxAltitude) {
        this.maxAltitudeText.style.color = "red";
        errors.push(i18n("operationplan.mandatory.maxAltitude"));
      }

      if (mtom < 0) {
        this.mtomText.style.color = "red";
        errors.push(i18n("operationplan.mandatory.mtomNegative"));
      }

      if (maxAltitude < 0) {
        this.maxAltitudeText.style.color = "red";
        errors.push(i18n("operationplan.mandatory.maxAltitudeNegative"));
      }

      if (api.user) {
        if (MANDATORY_FIELD_PUBLIC_TITLE && !description) {
          this.descriptionText.style.color = "red";
          errors.push(i18n("operationplan.mandatory.description"));
        }
      }

      if (!startDatetime) {
        this.startDatetimeText.style.color = "red";
        errors.push(i18n("operationplan.mandatory.startDatetime"));
      }

      if (!endDatetime) {
        this.endDatetimeText.style.color = "red";
        errors.push(i18n("operationplan.mandatory.endDatetime"));
      }

      this.errors.textContent = "";

      errors.forEach((alert) => {
        const $p = document.createElement("p");
        $p.textContent = alert;
        this.errors.appendChild($p);
      });

      if ((await checkValues("start")) || (await checkValues("end"))) {
        return;
      }

      if (errors.length) {
        return;
      }

      this.submit();
    };
  }

  onunmount() {
    this.api.browseTime = null;
    this.api.browseBuffer = 60;
    this.api.browseTimeChanged = true;
    this.api.browseTimeLocked = false;
    this.api.update();
    this.api.fetchUAS(true);
  }

  async update(api) {
    const { i18n } = this;
    const {
      REQ_AUTHORIZATION_MIN_LEAD_TIME,
      MANDATORY_FIELD_PUBLIC_TITLE,
      MIN_FLIGHT_DURATION,
    } = window.ENV;

    if (api.drawPlan) {
      this.el.style.display = "none";
    } else {
      this.el.style.display = "";
    }

    this.api = api;

    this.timeInfo.textContent = i18n("timeInfo");

    this.planFeatures = this.api.getPlanFeatures();
    this.reqAuthorization = !!this.planFeatures.find((feature) => {
      return feature.properties.restriction === "REQ_AUTHORISATION";
    });

    const start = new Date(this.startDatetime.value);
    const end = new Date(this.endDatetime.value);

    if (this.reqAuthorization) {
      const now = await serverTime();

      const minLeadTime = REQ_AUTHORIZATION_MIN_LEAD_TIME;
      if (start - now < minLeadTime * 60 * 1000) {
        const newStart = new Date(now.getTime() + minLeadTime * 60 * 1000);
        this.startDatetime.value = newStart;
        this.startDatetime.update();

        if (end - start < MIN_FLIGHT_DURATION * 60 * 1000) {
          const newEnd = new Date(
            newStart.getTime() + MIN_FLIGHT_DURATION * 60 * 1000
          );
          this.endDatetime.value = newEnd;
          this.endDatetime.update();
        }
      }
    } else {
      if (end - start < MIN_FLIGHT_DURATION * 60 * 1000) {
        const newEnd = new Date(
          start.getTime() + MIN_FLIGHT_DURATION * 60 * 1000
        );
        this.endDatetime.value = newEnd;
        this.endDatetime.update();
      }
    }

    const { user, drones } = api;

    this.firstName.parentNode.style.display = user ? "" : "none";
    this.lastName.parentNode.style.display = user ? "" : "none";
    this.email.parentNode.style.display = user ? "" : "none";
    this.phone.parentNode.style.display = user ? "" : "none";
    this.drone.el.parentNode.style.display = user ? "" : "none";
    this.description.parentNode.style.display = user ? "" : "none";

    this.contactDetailsText.textContent = user
      ? i18n("operationplan.contactDetails")
      : i18n("operationplan.precheck");
    this.firstNameText.textContent = i18n("operationplan.firstName") + " *";
    this.lastNameText.textContent = i18n("operationplan.lastName") + " *";
    this.emailText.textContent = i18n("operationplan.email") + " *";
    this.phoneText.textContent = i18n("operationplan.phone") + " *";
    this.droneText.textContent = i18n("operationplan.drone");
    this.typeOfOperationText.textContent =
      i18n("operationplan.operationType") + " *";
    if (MANDATORY_FIELD_PUBLIC_TITLE) {
      this.descriptionText.textContent =
        i18n("operationplan.description") + " *";
    } else {
      this.descriptionText.textContent = i18n("operationplan.description");
    }
    this.maxAltitudeText.textContent =
      i18n("operationplan.maxAltitude") + " (m) *";
    this.mtomText.textContent = i18n("operationplan.mtom") + " (g) *";
    this.startDatetimeText.textContent =
      i18n("operationplan.startDatetime") + " *";
    this.endDatetimeText.textContent = i18n("operationplan.endDatetime") + " *";

    this.typeOfOperation.options = [
      "REMOTELY_PILOTED_VLOS",
      "REMOTELY_PILOTED_BVLOS",
    ].map((value) => {
      return {
        value,
        text: i18n(`operationplan.operationType.${operationTypes[value]}`),
      };
    });

    this.typeOfOperation.onchange = () => {
      if (api.resubmitPlan) {
        api.resubmitPlan.typeOfOperation = this.typeOfOperation.value;
      }
    };

    if (this.phase === 1) {
      if (this.content) {
        this.content.scrollTop = 0;
      }
      if (api.resubmitPlan) {
        this.cancel.style.display = "none";
      }

      this.form.style.display = "";
      this.briefing.update(false);
    } else {
      this.cancel.style.display = "";
      const drone = this.getDrone[this.drone.value] || {};
      this.form.style.display = "none";
      this.briefing.update(true, {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        phone: this.phone.value,
        drone: drone.name || i18n("notprovided"),
        mtom: this.mtom.value || "",
        typeOfOperation: operationTypes[this.typeOfOperation.value] || "",
        description: this.description.value,
        maxAltitude: this.maxAltitude.value,
        startDatetime: this.startDatetime.value,
        endDatetime: this.endDatetime.value,
        serial: drone.serial || i18n("notprovided"),
        networkId: drone.networkId || i18n("notprovided"),
        features: (this.planFeatures || []).map((feature) => {
          feature.source = "uas";
          return feature;
        }),
      });
    }

    if (user) {
      const {
        given_name: givenName,
        family_name: familyName,
        email,
        phone,
      } = user;

      setUnchanged(this.firstName, givenName || "");
      setUnchanged(this.lastName, familyName || "");
      setUnchanged(this.email, email || "");
      setUnchanged(this.phone, phone || "");
    }

    this.getDroneByNetworkId = drones.reduce((lookup, drone) => {
      lookup[drone.networkId] = drone;
      return lookup;
    }, {});
    this.getDrone = drones.reduce((lookup, drone) => {
      lookup[drone.serial] = drone;
      return lookup;
    }, {});
    this.drone.options = [{ name: i18n("select"), value: "" }]
      .concat(drones || [])
      .map((drone) => {
        const { serial: value, name: text } = drone;

        return { text, value: value || "" };
      });

    this.drone.onchange = () => {
      const drone = drones.find((drone) => drone.serial === this.drone.value);

      if (drone) {
        this.mtom.value = drone.mtom || "";
      }
    };

    if (api.resubmitPlan) {
      const {
        uasRegistrations,
        operationTrajectory,
        publicInfo,
        typeOfOperation,
      } = api.resubmitPlan;
      const mtom =
        uasRegistrations && uasRegistrations[0] && uasRegistrations[0].mtomG;
      const networkId =
        uasRegistrations && uasRegistrations[0] && uasRegistrations[0].droneId;
      const maxAltitude =
        operationTrajectory &&
        operationTrajectory.trajectoryElements &&
        operationTrajectory.trajectoryElements[0] &&
        operationTrajectory.trajectoryElements[0].altitude;
      const title = publicInfo && publicInfo.title;
      const drone = this.getDroneByNetworkId[networkId];

      setUnchanged(
        this.typeOfOperation,
        typeOfOperation || "REMOTELY_PILOTED_VLOS"
      );
      setUnchanged(this.mtom, mtom || "");
      setUnchanged(this.maxAltitude, maxAltitude || "");
      setUnchanged(this.description, title || "");
      setUnchanged(this.drone, (drone && drone.serial) || "");
    }
  }

  async submit() {
    const { i18n } = this;
    const { HOST, ALTITUDE_REFERENCE, SKID } = window.ENV;

    const { route, routeArea, routePoints } = getPlanArea(this.api);

    const feature = route.features[0];
    const coordinates =
      feature.geometry.type === "Point"
        ? [feature.geometry.coordinates]
        : feature.geometry.type === "Polygon"
        ? feature.geometry.coordinates[0]
        : feature.geometry.coordinates;

    const [lngStart, latStart] = coordinates[0];
    const [lngEnd, latEnd] = coordinates[coordinates.length - 1];

    const firstName = this.firstName.value || "";
    const lastName = this.lastName.value || "";
    const email = this.email.value || "";
    const phonenumber = this.phone.value || "";
    const description = this.description.value || "";
    const maxAltitude = Number(this.maxAltitude.value || 0);
    const gufi = uuidv1();

    const drone =
      this.drone.value &&
      this.api.dronesById &&
      this.api.dronesById[this.drone.value];

    const regNumber =
      this.api.user &&
      this.api.user.company &&
      this.api.user.company.registrationNumber;

    const skid = this.api.user && this.api.user.skid;

    const start = new Date(this.startDatetime.value);
    const end = new Date(this.endDatetime.value);

    const now = await serverTime();

    const plan = {
      aircraftComment: "",
      contactDetails: {
        comment: skid || "",
        emails: [email],
        fax: "",
        firstName,
        lastName: lastName + (SKID && skid ? ` ${skid}` : ""),
        phone: [phonenumber],
      },
      contingencyPlans: [],
      controllerLocation: {
        coordinates: [lngStart, latStart],
        type: "Point",
      },
      flightDetails: [
        {
          flightComment: description,
          flightNumber: "",
          flightType: "",
          maxFlightSpeedKnots: 0,
        },
      ],
      formationId: "",
      formationOpIds: [],
      gcsLocation: {
        coordinates: [lngStart, latStart],
        type: "Point",
      },
      /* gufi, */
      landingLocation: {
        coordinates: [lngEnd, latEnd],
        type: "Point",
      },
      minContOpTime: 0,
      operationPlanId: gufi,
      operationTrajectory: {
        altitudeCRS: "WGS84",
        altitudeType: ALTITUDE_REFERENCE || "ABOVE_ELLIPSOID",
        altitudeUncertainty: 10,
        positionCRS: "WGS84",
        positionUncertainty: 10,
        timingUncertainty: 10,
        trajectoryElements: routePoints.features.map((feature, i) => {
          const time = end - start;

          const startTS =
            start.getTime() + (time * i) / routeArea.features.length;
          const endTS =
            start.getTime() + (time * (i + 1)) / routeArea.features.length;
          const startTime = new Date(startTS).getTime();
          const endTime = new Date(endTS).getTime();

          return {
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1],
            altitude: maxAltitude,
            time: new Date(startTime + (endTime - startTime) / 2).toISOString(),
          };
        }),
      },
      operationVolumes: routeArea.features.map((feature, i) => {
        const time = end - start;

        const startTS =
          start.getTime() + (time * i) / routeArea.features.length;
        const endTS =
          start.getTime() + (time * (i + 1)) / routeArea.features.length;
        const startTime = new Date(startTS);
        const endTime = new Date(endTS);

        return {
          actualTimeEnd: end,
          alias: "",
          isBVLOS: this.typeOfOperation.value === "REMOTELY_PILOTED_BVLOS",
          isNearStructure: true,
          operationGeometry: {
            geom: {
              coordinates: feature.geometry.coordinates,
              type: "Polygon",
            },
            maxAltitude: {
              altitudeValue: metersToFeet(maxAltitude),
              altitudeType: ALTITUDE_REFERENCE || "ABOVE_ELLIPSOID",
              unitsOfMeasure: "FT",
            },
            minAltitude: {
              altitudeValue: 0,
              altitudeType: ALTITUDE_REFERENCE || "ABOVE_ELLIPSOID",
              unitsOfMeasure: "FT",
            },
          },
          ordinal: 0,
          priority: {
            priorityLevelCorus: null,
            priorityLevelSimple: "PRIO_LOW",
            priorityText: "",
          },
          timeBegin: startTime.toISOString(),
          timeEnd: endTime.toISOString(),
          volumeType: "ABOV",
        };
      }),
      operator: regNumber || email,
      previousVersions: [],
      priority: {
        priorityLevelCorus: "PRIO_1_EMERGENCY",
        priorityLevelSimple: "PRIO_LOW",
        priorityText: "",
      },
      publicInfo: {
        description: "",
        title: description,
      },
      state: "PROPOSED",
      swarmSize: 0,
      takeoffLocation: {
        coordinates: [lngStart, latStart],
        type: "Point",
      },
      typeOfOperation: this.typeOfOperation.value,
      uasRegistrations: [
        {
          droneId: (drone || {}).networkId || (drone || {}).serial || "",
          registrationLocation: "at",
          mtomG: this.mtom.value || 0,
        },
      ],
      submitTime: now,
      updateTime: now,
      ussName: "aviagof2",
      version: gufi,
    };

    if (this.phase === 1) {
      this.phase = 2;
      if (this.content) {
        this.content.scrollTop = 0;
      }

      this.api.browseTime = new Date(start.getTime());
      this.api.browseBuffer = Math.ceil(
        (end.getTime() - start.getTime()) / 1000 / 60
      );
      this.api.browseTimeChanged = true;
      this.api.browseTimeLocked = true;
      this.api.fetchUAS(true);

      this.conflicts = [];

      try {
        const json = await post(`${HOST}utm/operationplan/deconflict`, {
          body: JSON.stringify({
            ...plan,
            operator: "deconfliction",
          }),
        });
        this.conflicts = JSON.parse(json);
        this.api.update();
      } catch (err) {
        console.log(new Error(err));
      }

      this.api.update();
      return;
    }
    const onclick = this.briefing.view.send.onclick;
    const innerHTML = this.briefing.view.send.innerHTML;

    this.briefing.view.send.onclick = () => {};
    this.briefing.view.send.innerHTML =
      '<i class="ti ti-loader-2 ti-spin"></i>';

    let notified = false;
    try {
      await post(
        `${HOST}utm/operationplan/notify`,
        {
          body: JSON.stringify(plan),
        },
        (errStatus) => {
          if (errStatus === 401) {
            notified = true;
            return alert(i18n("operationplan.error.unauthorized"));
          }
        }
      );
      this.briefing.view.send.onclick = onclick;
      this.briefing.view.send.innerHTML = innerHTML;

      this.api.createPlan = false;
      this.app.menubutton.el.click();
      this.api.menuSection = "operationplans";
      this.api.update();
      this.api.app.map.reloadJSON("operationplans");
    } catch (err) {
      if (err === 401) {
        return alert(i18n("operationplan.error.unauthorized"));
      }
      this.briefing.view.send.onclick = onclick;
      this.briefing.view.send.innerHTML = innerHTML;
      if (
        err.includes &&
        (err.includes(
          "Operation volumes cannot be VLOS when its bounding box size is larger than"
        ) ||
          err.includes("VLOS flight maximum dimensions exceeded"))
      ) {
        alert(i18n("summary.error.VLOS_BOUNDING_BOX_SIZE_TOO_BIG"));
      } else if (err.includes && err.includes("Unauthorized")) {
        alert(i18n("operationplan.error.unauthorized"));
      } else {
        if (!notified) {
          alert(err);
        }
      }
      return console.error(err);
    }
  }
}

class Briefing {
  constructor({ api, plan, i18n }) {
    this.api = api;
    this.plan = plan;
    this.i18n = i18n;
    this.el = el(
      ".briefing",
      (this.summaryText = el("h2")),
      el(
        ".row",
        el(
          ".col2",
          el(
            "p",
            (this.startDatetimeText = el("b")),
            el("br"),
            (this.startDatetime = el("span"))
          )
        ),
        el(
          ".col2",
          el(
            "p",
            (this.endDatetimeText = el("b")),
            el("br"),
            (this.endDatetime = el("span"))
          )
        )
      ),
      el(
        ".row",
        el(
          ".col2",
          el("p", (this.mtomText = el("b")), el("br"), (this.mtom = el("span")))
        ),
        el(
          ".col2",
          el(
            "p",
            (this.maxAltitudeText = el("b")),
            el("br"),
            (this.maxAltitude = el("span"))
          )
        )
      ),
      el(
        ".row",
        el(
          ".col",
          el(
            "p",
            (this.typeOfOperationText = el("b")),
            el("br"),
            (this.typeOfOperation = el("span"))
          )
        )
      ),
      el(
        ".row",
        el(
          ".col2",
          el(
            "p",
            (this.droneText = el("b")),
            el("br"),
            (this.drone = el("span"))
          )
        ),
        el(
          ".col2",
          el(
            "p",
            (this.aircraftSerialText = el("b")),
            el("br"),
            (this.aircraftSerial = el("span"))
          )
        )
      ),
      el(
        ".row",
        el(
          ".col2",
          el(
            "p",
            (this.firstNameText = el("b")),
            el("br"),
            (this.firstName = el("span"))
          )
        ),
        el(
          ".col2",
          el(
            "p",
            (this.lastNameText = el("b")),
            el("br"),
            (this.lastName = el("span"))
          )
        )
      ),
      el(
        ".row",
        el(
          ".col2",
          el(
            "p",
            (this.emailText = el("b")),
            el("br"),
            (this.email = el("span"))
          )
        ),
        el(
          ".col2",
          el(
            "p",
            (this.phoneText = el("b")),
            el("br"),
            (this.phone = el("span"))
          )
        )
      ),
      el(
        ".row",
        el(
          ".col",
          el(
            "p",
            (this.descriptionText = el("b")),
            el("br"),
            (this.description = el("span"))
          )
        )
      ),
      el(
        "p",
        { style: { marginTop: "1rem" } },
        (this.instructionsText = el("b"))
      ),
      (this.features = list(".features", Feature, null, { api, i18n })),
      el(
        "p",
        { style: { marginTop: "1rem" } },
        (this.smallprintText = el("b"))
      ),
      el(
        "div",
        { style: { textAlign: "right", paddingTop: "1rem" } },
        el(
          "div",
          { style: { display: "inline-block", marginRight: ".5rem" } },
          (this.cancel = el(".button.grey.circle", el("i.ti.ti-arrow-left")))
        ),
        el(
          "div",
          { style: { display: "inline-block" } },
          (this.send = el(".button.circle", el("i.ti.ti-send")))
        )
      )
    );

    this.send.onclick = () => {
      if (!this.api.user) {
        return alert(i18n("operationplan.error.signInFirst"));
      }
      if (!this.api.user.active) {
        return alert(i18n("operationplan.error.accountSuspended"));
      }
      this.plan.submitButton.click();
    };

    this.cancel.onclick = () => {
      this.plan.phase = 1;
      if (this.content) {
        this.content.scrollTop = 0;
      }
      this.api.update();
      this.send.classList.remove("disabled");
    };
  }

  update({
    firstName,
    lastName,
    email,
    phone,
    drone,
    typeOfOperation,
    description,
    maxAltitude,
    startDatetime,
    endDatetime,
    serial,
    features,
    mtom,
    skid,
  }) {
    const { i18n } = this;

    this.summaryText.textContent = i18n("summary");

    if (!this.api.user) {
      this.send.classList.add("disabled");
    } else if (!this.api.user.active) {
      this.send.classList.add("disabled");
    } else {
      this.send.classList.remove("disabled");
    }

    const conflictsById = this.plan.conflicts.reduce((results, conflict) => {
      results[conflict.objectId] || (results[conflict.objectId] = []);
      results[conflict.objectId].push(conflict);
      return results;
    }, {});
    const conflictsFound = {};

    features.forEach((feature) => {
      const { country, identifier } = feature.properties;
      conflictsFound[country + identifier] = true;
    });

    const _features = this.plan.conflicts
      .filter((conflict) => {
        return !conflictsFound[conflict.objectId];
      })
      .map((conflict) => {
        const { conflictType, objectType, objectId, message } = conflict;

        return {
          source: "uas",
          properties: {
            justConflict: true,
            conflictType,
            objectType,
            objectId,
            message,
          },
        };
      })
      .concat(
        features
          .filter((feature) => {
            const {
              country,
              identifier,
              extendedProperties = {},
            } = feature.properties;
            const conflicts = conflictsById[country + identifier] || [];
            const noRestriction =
              feature.properties.restriction === "NO_RESTRICTION";

            const { authorityRequirementConditions = [] } = extendedProperties;
            const conditionsCount = authorityRequirementConditions.length;

            let conflicting = false;

            for (const conflict of conflicts) {
              const { conflictType } = conflict;

              if (conflictType === "TEXTUAL_RESTRICTION") {
                if (conditionsCount === 0) {
                  conflicting = true;
                }
              } else {
                conflicting = true;
              }
            }

            return conflicting || noRestriction;
          })
          .map((feature) => {
            const { country, identifier } = feature.properties;
            const conflictsFound = conflictsById[country + identifier];
            const _rejecting =
              conflictsFound &&
              conflictsFound.find((conflict) => conflict.rejecting);

            return {
              ...feature,
              properties: {
                ...feature.properties,
                _rejecting,
              },
            };
          })
      );

    this.api.sortFeatures(_features);

    this.features.update(_features);

    this.firstNameText.textContent = i18n("operationplan.firstName");
    this.lastNameText.textContent = i18n("operationplan.lastName");
    this.emailText.textContent = i18n("operationplan.email");
    this.phoneText.textContent = i18n("operationplan.phone");
    this.droneText.textContent = i18n("operationplan.drone");
    this.mtomText.textContent = i18n("operationplan.mtom");
    this.descriptionText.textContent = i18n("operationplan.description");
    this.typeOfOperationText.textContent = i18n("operationplan.operationType");
    this.aircraftSerialText.textContent = i18n("operationplan.aircraftSerial");
    this.maxAltitudeText.textContent = i18n("operationplan.maxAltitude");
    this.startDatetimeText.textContent = i18n("operationplan.startDatetime");
    this.endDatetimeText.textContent = i18n("operationplan.endDatetime");
    this.instructionsText.textContent = this.api.user
      ? i18n("summary.instructions")
      : i18n("summary.precheckInstructions");
    this.smallprintText.textContent = this.api.user
      ? i18n("summary.smallprint")
      : i18n("summary.precheckSmallprint");

    this.instructionsText.style.display = _features.length ? "" : "none";

    this.drone.parentNode.style.display = this.api.user ? "" : "none";
    this.aircraftSerialText.parentNode.style.display = this.api.user
      ? ""
      : "none";
    this.firstName.parentNode.style.display = this.api.user ? "" : "none";
    this.lastName.parentNode.style.display = this.api.user ? "" : "none";
    this.email.parentNode.style.display = this.api.user ? "" : "none";
    this.phone.parentNode.style.display = this.api.user ? "" : "none";
    this.description.parentNode.style.display = this.api.user ? "" : "none";

    this.firstName.textContent = firstName || "";
    this.lastName.textContent = lastName || "";
    this.email.textContent = email || "";
    this.phone.textContent = phone || "";
    this.drone.textContent = drone || "";
    this.aircraftSerial.textContent = serial || "";
    this.mtom.textContent = format(mtom, "g");
    this.typeOfOperation.textContent = i18n(
      `operationplan.operationType.${typeOfOperation}`
    );
    this.description.textContent = description || "";
    this.maxAltitude.textContent = format(maxAltitude, "m");
    this.startDatetime.textContent =
      (startDatetime && humanDateTime(startDatetime)) || "";
    this.endDatetime.textContent =
      (endDatetime && humanDateTime(endDatetime)) || "";
  }
}

class Option {
  constructor() {
    this.el = el("option");
  }

  update(data) {
    const { text, value } = data;

    this.el.textContent = text;
    this.el.value = value;
  }
}

function setUnchanged(input, value) {
  if (input._value !== value) {
    input.value = value;
    input._value = value;
  }
}

function validPhone(phone) {
  const validCharacters = "0123456789 ";
  let check = phone.slice();
  if (check[0] === "+") {
    check = check.slice(1);
  }
  for (let i = 0; i < check.length; i++) {
    const char = check[i];
    if (!validCharacters.includes(char)) {
      return false;
    }
  }
  return true;
}

function pad2(val) {
  return `0${val}`.slice(-2);
}

function humanDateTime(date) {
  const day = pad2(date.getDate());
  const month = pad2(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad2(date.getHours());
  const minutes = pad2(date.getMinutes());

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

function format(value, unit) {
  if (value) {
    return `${value} ${unit}`;
  } else {
    return "-";
  }
}
